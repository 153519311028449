/* eslint-disable vue/no-unused-components */
<template>
  <div class="auto-withdraw">
    <header>
      <p class="auto-withdraw-title">
        <span class="line-mark"></span><span class="auto-withdraw-title-img">证件照片</span>
      </p>
      <el-select :disabled='true' v-model="form.industryQualificationName" clearable placeholder="请选择行业" style="margin-left: 20px">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <ul class="auto-withdraw-photo-cards">
        <li style="width: 173px" v-for="(item, index) in imgUploadList" :key="index">
          <!-- @click="changeHeadImg(item.url)" -->
          <div class="money-img-code">
            <img :src="form[item.url] ? form[item.url] : unploadImg" class="code-img-money" style="width: 173px; height: 113px" />
            <input class="hide" type="file" accept="image/*" style="display: none" />
          </div>
          <p>{{ item.title }}</p>
        </li>
      </ul>
    </header>
    <article>
      <p class="auto-withdraw-title">
        <span class="line-mark"></span><span class="auto-withdraw-title-img">物资信息</span>
      </p>
      <div class="auto-withdraw-slice-line"></div>
      <!-- 表单部分 -->
      <el-form ref="form" :model="form" label-width="140px" style="padding: 20px" class="formStyle">
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="商家名称">
              <el-input :disabled='true' v-model="form.shopName" placeholder="依据营业执照填写"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商家联系方式">
              <el-input :disabled='true' v-model="form.businessPhone" placeholder="输入商家电话号码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="营业执照名称">
              <el-input :disabled='true' v-model="form.businessName" placeholder="输入营业执照名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8" :offset="0">
            <el-form-item label="商家营业执照号">
              <el-input :disabled='true' v-model="form.businessNumber" placeholder="依据营业执照填写"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="0">
            <el-form-item label="法定代表人姓名">
              <el-input :disabled='true' v-model="form.legalPersonName" placeholder="输入营业执照号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="0">
            <el-form-item label="法定代表人身份证">
              <el-input :disabled='true' v-model="form.legalPersonIdCardNumber" placeholder="输入身份证号码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="22" :offset="0">
            <el-form-item label="商家宣称语">
              <el-input :disabled='true' type="textarea" v-model="form.shopDesc" placeholder="填写商家业务及优势即可,最多可输入120个字"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <footer>
          <div v-if="!show">
  <div class="automatic-withdraw-submit-save pointer" @click="finaSave" v-if="status==2">
            通过
          </div>
          <div class="automatic-withdraw-submit-save pointer" style="background: #4d4d4d" @click="rejectOprate" v-if="status==2">
            {{unload?'取消':'驳回'}}
          </div>
          </div>
          <div class="automatic-withdraw-submit-save pointer" style="background: #4d4d4d" @click="rejectOprate" v-if="show">
           取消
          </div>
        </footer>
      </el-form>
    </article>
    <!-- 确认弹窗 -->
    <explore-dialog :dialogData="dialogData" ref="exploreDialog" @sureSubmit="sureSubmit">
      <el-input v-if="state=='reject'" type="textarea" v-model="rejectReason" placeholder="" size="normal" clearable></el-input>
    </explore-dialog>
    <Dialog ref="auditPage">
      <div class="audit-footer-button">
        <el-button type="info" class="sure" @click="offDialog">取消</el-button>
        <el-button type="primary" class="sure" @click="sureAudit">确定</el-button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import unploadImg from '@/assets/img/common/unploadImg.png';
import exploreDialog from '@/components/common/exploreDialog.vue';
import { $sp_accountDetail, $sp_auditAccount } from '@/api/ad';
import Dialog from '@/components/common/dialog.vue';

const imgUploadList = [
  {
    url: 'shopFacePicq',
    title: '商家门头照',
  },
  {
    url: 'idCardFront',
    title: '身份证正面照',
  },
  {
    url: 'idCardBack',
    title: '身份证背面照',
  },

  {
    url: 'businessPath',
    title: '营业执照照片',
  },
  {
    url: 'industryQualificationPic',
    title: '行业资质照片',
  },
];

const urlRegular = {
  idCardFront: { reg: /^.*$/, require: '请上传身份证正面照' },
  idCardBack: { reg: /^.*$/, require: '请上传身份证反面照' },
  legalPersonHandlerPic: { reg: /^.*$/, require: '请上传手持身份证照片' },
  shopFacePic: { reg: /^.*$/, require: '请上传店铺门头照' },
  shopInPic: { reg: /^.*$/, require: '请上传店铺内景照' },
  businessPath: { reg: /^.*$/, require: '请上传营业执照照片' },
  bankCardPic: { reg: /^.*$/, require: '请上传银行卡照片' },
};
export default {
  // eslint-disable-next-line no-undef
  components: { exploreDialog, Dialog },
  data() {
    return {
      show: false,
      options: [],
      dialogs: {},
      showDialog: false,
      rejectReason: '',
      state: false,
      status: null,
      values: '',
      idTimeLong: false,
      businessTimePermanent: false,
      urlRegular,
      addressFlag: true,
      unploadImg,
      url: '',
      imgUploadList,
      imgUrlList: {
        idCardFront: '',
        idCardBack: '',
        legalPersonHandlerPic: '',
        shopFacePic: '',
        shopInPic: '',
        businessPath: '',
        bankCardPic: '',
        bankProvincesJson: [],
      },
      form: {

      },
      value: '',
      bankList: [],
      address: [],
      addTime: null,
      finAddress: null,
      addressList: [],
      dialogData: {
        title: 'dddddd',
        content: 'ddddddddddddd',
      },
      unload: null,
    };
  },
  computed: {},

  created() { },
  mounted() {
    const id = this.$route.query.id;
    this.status = this.$route.query.state;
    this.show = this.$route.query.show;
    this.accountDetail(id);
    if (this.$route.query.unload) {
      this.unload = this.$route.query.unload;
    }
  },
  // 方法集合
  methods: {
    sureSubmit() {
      const id = this.$route.query.id;
      if (this.state === 'submit') {
        $sp_auditAccount({ type: 2, action: 1 }, id).then(() => {
          this.$refs.exploreDialog.exploreVisible = false;
          this.$router.back();
        });
      } else {
        if (!this.rejectReason) {
          this.$message.error('请填写驳回理由');
          return;
        }
        $sp_auditAccount({ type: 2, reason: this.rejectReason, action: 2 }, id).then(() => {
          this.$refs.exploreDialog.exploreVisible = false;
          this.$router.back();
        });
      }
    },
    onSubmit() { },
    // 保存数据
    finaSave() {
      if (this.unload) {
        this.$refs.auditPage.dialogs = {
          icon: '/img/ad/printSucess.png',
          title: '提示',
          content: '确定已将资料提交至微信审核?若资料有误请线下联系商户更改相关资料。',
        };
        this.$refs.auditPage.showDialog = true;
      } else {
        this.state = 'submit';
        this.$refs.exploreDialog.exploreVisible = true;
        this.dialogData = {
          title: '提示',
          content: '请核对反馈商户微信审核结果，若开户审核失败请联系商户更改相关资料',
        };
      }
    },
    rejectOprate() {
      if (this.unload) {
        this.$router.back();
      } else {
        this.state = 'reject';
        this.$refs.exploreDialog.exploreVisible = true;

        this.dialogData = {
          title: '驳回理由',
        // content: '请确认已将资料提交至微信审核，确认要审核通过吗？',
        };
      }
    },
    accountDetail(param) {
      $sp_accountDetail(param).then((res) => {
        console.log(res);
        this.form = res;
      });
    },
    sureAudit() {
      const id = this.$route.query.id;
      $sp_auditAccount({ type: 1, action: 1 }, id).then(() => {
        this.$router.back();
      });
    },
    offDialog() {
      this.$refs.auditPage.showDialog = false;
    },
  },
};
</script>
<style lang='scss' >
.auto-withdraw {
   .audit-footer-button {
    margin: 20px auto;
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
  header {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 6px;
    .auto-withdraw-title {
      padding: 20px;
      .line-mark {
        display: inline-block;
        width: 3px;
        height: 19px;
        background: #ef3f46;

        margin-right: 10px;
      }
      .auto-withdraw-title-img {
        vertical-align: 3px;
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }
    }
    .auto-withdraw-warn {
      height: 35px;
      background: #ffe4e1;
      img {
        padding: 8px 10px 0 20px;
      }
      .auto-withdraw-warn-text {
        font-size: 12px;
        line-height: 35px;
        color: #f63d29;
      }
    }
    .auto-withdraw-photo-cards {
      width: 80%;
      padding: 20px 30px 30px 30px;
      display: flex;
      flex-flow: wrap;
      //   justify-content: space-between;
      li {
        margin-right: 80px;
      }
      div {
        cursor: pointer;
      }
      p {
        font-size: 14px;
        line-height: 40px;
        color: #999999;
        text-align: center;
      }
    }
    .industry-img {
      display: inline-block !important;
      width: unset !important;
      margin-left: 30px;
    }
  }
  article {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 6px;
    .auto-withdraw-title {
      padding: 20px;
      .line-mark {
        display: inline-block;
        width: 3px;
        height: 19px;
        background: #ef3f46;

        margin-right: 10px;
      }
      .auto-withdraw-title-img {
        vertical-align: 3px;
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }
    }
    .auto-withdraw-slice-line {
      height: 0px;
      border: 1px solid #e4e4e4;
    }
    .formStyle {
      padding: 20px;
      .el-input {
        width: 200px;
      }
    }
    footer {
      text-align: center;
      margin: 30px auto 0px auto;
      .automatic-withdraw-submit-save {
        background: #ef3f46;
        width: 128px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 32px;
        color: #ffffff;
        display: inline-block;
        text-align: center;
        margin-right: 10px;
      }
    }
  }
  aside {
    p {
      font-size: 12px;
      line-height: 17px;
      color: #ef3f46;
    }

    margin-top: 20px;
    background: #ffffff;
    opacity: 1;
    border-radius: 6px;
    padding-bottom: 40px;
    .auto-withdraw-title {
      padding: 20px;
      .line-mark {
        display: inline-block;
        width: 3px;
        height: 19px;
        background: #ef3f46;

        margin-right: 10px;
      }
      .auto-withdraw-title-img {
        vertical-align: 3px;
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }
    }
    .auto-withdraw-slice-line {
      height: 0px;
      border: 1px solid #e4e4e4;
    }
    .formStyle {
      padding: 20px;
      .el-input {
        width: 180px;
      }
    }
  }
}
</style>
