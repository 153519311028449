<template>
  <div>
    <el-dialog class="explore-dialog" :visible="exploreVisible" width="420px" @close='close'>
      <header class="title-img">
        <img :src="dialogData.img || defaultImg" alt="" />
      </header>
      <p class="explore-title">{{ dialogData.title }}</p>
      <p class="explore-content" v-if="dialogData.content">{{ dialogData.content }}</p>
       <div class="slot-content">
           <slot></slot>
       </div>
       <footer>
      <span class="cancle-dialog" @click='close'>取消</span>
      <span class="sure-dialog" @click="sureSubmit">确定</span>
    </footer>
    </el-dialog>
   
  </div>
</template>

<script>
import defaultImg from '@/assets/img/common/exploreImg.png';

export default {
  components: {},
  data() {
    return {
      defaultImg,
      exploreVisible: false,
    };
  },
  props: {
    dialogData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  // 方法集合
  methods: {
    close() {
      this.exploreVisible = false;
    },
    sureSubmit() {
      this.$emit('sureSubmit');
    },
  },
};
</script>
<style lang='scss' >
.explore-dialog {
  .el-dialog__header {
    background: #f8f7f7;
    padding-bottom: 20px;
  }
  .title-img {
    margin: 0px auto 0px auto;
    text-align: center;
  }
  .slot-content{
      margin: 0px 0 20px 0;
  }
  .explore-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    color: #4d4d4d;
    text-align: center;
    line-height: 50px;
  }
  .explore-content {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.65);
    opacity: 1;
    padding-bottom: 20px;
    text-align: center;
  }
  footer{
   width: 90%;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   margin: 0 auto;
   cursor: pointer;
  .cancle-dialog {
    width: 110px;
    height: 32px;
    border: 1px solid #e4e4e4;
    opacity: 1;
    border-radius: 4px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    
  }
  .sure-dialog {
   
    width: 110px;
    height: 32px;
    background: #EF3F46;
    opacity: 1;
    border-radius: 4px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
  }
  }
}
</style>
